@import "abstracts/_abstracts";
.SpinnerGlobal {
    $block: &;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: var(--colorShadeBg);
    pointer-events: none;
    z-index: var(--layerSpinnerZIndex);
    transition: var(--animationBase);

    &.is-active {
        display: flex;
        pointer-events: auto;
    }

    &-spinner {
        display: none;
        margin: auto;
        width: 25px;
        height: 25px;

        #{$block}.is-active & {
            display: block;
            animation: spinner 2s linear infinite;

            &::before, &::after {
                display: block;
                border-radius: 50%;
                content: '';
                width: 25px;
                height: 25px;
                background-color: var(--colorBrand);
            }

            &::before {
                margin-left: 25px;
            }

            &::after {
                margin-top: -25px;
                margin-left: -25px;
            }
        }
    }

    @keyframes spinner {
        0% {
        }
        100% {
            transform: rotate(360deg);
        }
    }
}